import React from 'react';
import {
	FlexProps,
	Flex,
	Image,
	Text,
	Heading,
	Box,
	Link,
	AspectRatioBox,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { CTALink } from './ctaLink';
import { TransitLayer } from '@react-google-maps/api';

const StyledFlex = styled(Flex)`
	background-color: #ffffff;
	border-radius: 4px;
	/* margin-right: 12px; */

	/* flex-direction: column;

	@media (min-width: 565px) {
		flex-direction: row;
	}
	@media (min-width: 800px) {
		flex-direction: column;
	} */

	/* &:last-child {
		margin-right: 0px;
	} */

	.clipped-text {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
`;

const StyledBox = styled(Box)`
	.clipped-text {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	/* &:last-child {
		margin-right: 12px;
	} */
`;

interface ICard {
	title: string;
	bodyText?: string;
	ctaLink?: URL | string;
	ctaLinkText?: string;
	featureImageUrl?: URL | string;
	rest?: FlexProps;
}

export const Card = ({
	title,
	bodyText,
	ctaLink,
	ctaLinkText,
	featureImageUrl,
	...rest
}: ICard) => {
	let isV1 = false;
	return (
		// @TODO make these slightly responsive for better re-use on season calendar etc.
		isV1 ? (
			<StyledFlex
				shadow="sm"
				flexDir={{ base: 'column', md: 'row', xl: 'column' }}
				w={{ base: 'auto', md: '100%', xl: 'auto' }}
				{...rest}
			>
				<Image
					src={featureImageUrl}
					width="350px"
					// minW="350px"
					height="197px"
					roundedTop="4px"
				/>
				<Flex direction="column" p={4} height="100%" minW="319px" mx="auto">
					<Heading size="lg" mt={2}>
						{title}
					</Heading>
					<Text size="xs" height="min-content" mt={1} className="clipped-text">
						{bodyText}
					</Text>
					{ctaLink && (
						<CTALink text={ctaLinkText || 'Read Article'} href={ctaLink} />
					)}
				</Flex>
			</StyledFlex>
		) : (
			<StyledBox
				display={{ md: 'flex', xl: 'block' }}
				shadow="sm"
				rounded="md"
				// maxW="700px"
				// minW={{ xl: '350px' }}
				{...rest}
			>
				<Box
					// d="none"
					rounded={'md'}
					width={{ md: 200, xl: 'unset' }}
					minH={{ base: '200px', xl: '280px' }}
					maxH={{ base: '250px', xl: 'unset' }}
					minW={{ base: '250px', sm: '350px', md: '350px' }}
					// maxW=
					backgroundSize="cover"
					backgroundRepeat="no-repeat"
					bgPos="center"
					bgImage={`url('${featureImageUrl}')`}
				>
					{/* <Image
						roundedTop="md"
						width={{ md: 200 }}
						src={featureImageUrl}
						alt="Woman paying for a purchase"
					/> */}
				</Box>
				<Box
					p={4}
					mt={{ base: 4, md: 0 }}
					ml={{ md: 6 }}
					minW={{ base: 'auto', lg: 'auto', xl: '38ch' }}
				>
					<Heading size="lg" mt={2}>
						{title}
					</Heading>

					<Text mt={2} mb={4} color="gray.500" className="clipped-text">
						{bodyText}
					</Text>
					{ctaLink && (
						<CTALink
							text={ctaLinkText || 'Read Article'}
							href={ctaLink}
							mt={3}
						/>
					)}
				</Box>
			</StyledBox>
		)
	);
};
