import React from 'react';
import {
	Flex,
	Link,
	Image,
	Heading,
	Text,
	Box,
	Icon,
	LinkProps,
} from '@chakra-ui/core';
import styled from '@emotion/styled';

const CTA = styled(Link)`
	.arrow {
		transition: all 0.2s ease-in-out;
	}
	&:hover {
		.arrow {
			transform: translateX(4px);
		}
	}
`;

// interface ICTALink {
// 	text: string;
// 	props: LinkProps;
// }

export const CTALink = ({
	text,
	...rest
}: {
	text: string;
	rest?: LinkProps;
}) => {
	return (
		<CTA py={3} {...rest}>
			{text} <Icon className="arrow" name="chevron-right" color="#4eb75d" />
		</CTA>
	);
};
