import React from 'react';
import { PageLayout } from '../components/pageLayout';
import { Flex, Heading, Text, Box } from '@chakra-ui/core';
import { Card } from '../components/Card';
import { Container } from '../components/Container';

interface Props {}

const ourStory = (props: Props) => {
	return (
		<PageLayout>
			<Container>
				<Flex
					justifyContent="center"
					textAlign="left"
					alignItems="left"
					flexDir="column"
					mx="auto"
					width="90ch"
					mt={100}
				>
					<Heading fontSize="xs">Heading xs</Heading>
					<Heading fontSize="sm">Heading sm</Heading>
					<Heading fontSize="md">Heading md</Heading>
					<Heading fontSize="lg">Heading lg</Heading>
					<Heading fontSize="xl">Heading xl</Heading>
					<Heading fontSize="2xl">Heading 2xl</Heading>
					<Heading fontSize="3xl">Heading 3xl</Heading>
					<Heading fontSize="4xl">Heading 4xl</Heading>
					<Heading fontSize="5xl">Heading 5xl</Heading>
					<Heading fontSize="6xl">Heading 6xl</Heading>
					<br />
					<Text fontSize="xs">Text xs</Text>
					<Text fontSize="sm">Text sm</Text>
					<Text fontSize="md">Text md</Text>
					<Text fontSize="lg">Text lg</Text>
					<Text fontSize="xl">Text xl</Text>
					<Text fontSize="2xl">Text 2xl</Text>
					<Text fontSize="3xl">Text 3xl</Text>
					<Text fontSize="4xl">Text 4xl</Text>
					<Text fontSize="5xl">Text 5xl</Text>
					<Text fontSize="6xl">Text 6xl</Text>
				</Flex>
				<Flex flexWrap="wrap">
					<Card
						title="test"
						featureImageUrl="https://images.prismic.io/thompsonfresh/afc00b4b-9fef-474c-a268-7ac934593ceb_image%403x+copy+2.jpg?auto=compress,format"
						bodyText="slkdfjlkasjdf asjdlkf jal;skj fl;kasjfl;kasjdflk;ajsdfkl;jaslkfjal;s dfl;kjaskldfjl;kas"
					/>
					<Card
						title="test"
						featureImageUrl="https://images.prismic.io/thompsonfresh/afc00b4b-9fef-474c-a268-7ac934593ceb_image%403x+copy+2.jpg?auto=compress,format"
						bodyText="slkdfjlkasjdf asjdlkf jal;skj fl;kasjfl;kasjdflk;ajsdfkl;jaslkfjal;s dfl;kjaskldfjl;kas"
					/>
					<Card
						title="test"
						featureImageUrl="https://images.prismic.io/thompsonfresh/afc00b4b-9fef-474c-a268-7ac934593ceb_image%403x+copy+2.jpg?auto=compress,format"
						bodyText="slkdfjlkasjdf asjdlkf jal;skj fl;kasjfl;kasjdflk;ajsdfkl;jaslkfjal;s dfl;kjaskldfjl;kas"
					/>
					<Card
						title="test"
						featureImageUrl="https://images.prismic.io/thompsonfresh/afc00b4b-9fef-474c-a268-7ac934593ceb_image%403x+copy+2.jpg?auto=compress,format"
						bodyText="slkdfjlkasjdf asjdlkf jal;skj fl;kasjfl;kasjdflk;ajsdfkl;jaslkfjal;s dfl;kjaskldfjl;kas"
					/>
					<Card
						title="test"
						featureImageUrl="https://images.prismic.io/thompsonfresh/afc00b4b-9fef-474c-a268-7ac934593ceb_image%403x+copy+2.jpg?auto=compress,format"
						bodyText="slkdfjlkasjdf asjdlkf jal;skj fl;kasjfl;kasjdflk;ajsdfkl;jaslkfjal;s dfl;kjaskldfjl;kas"
					/>
					<Card
						title="test"
						featureImageUrl="https://images.prismic.io/thompsonfresh/afc00b4b-9fef-474c-a268-7ac934593ceb_image%403x+copy+2.jpg?auto=compress,format"
						bodyText="slkdfjlkasjdf asjdlkf jal;skj fl;kasjfl;kasjdflk;ajsdfkl;jaslkfjal;s dfl;kjaskldfjl;kas"
					/>
				</Flex>
			</Container>
		</PageLayout>
	);
};

export default ourStory;
